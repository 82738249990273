/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "jstyle.css";

table {
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 0.9em;
    font-family: sans-serif;
    min-width: 400px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    width: 100%;
    table-layout: fixed;
}

table thead tr {
    background-color: #fff;
    color: #000;
    text-align: left;
    border-bottom: 1px solid #e7e7e7;
}

table th,
table td {
    padding: 12px 15px;
}

table tbody tr {
    border-bottom: 1px solid #dddddd;
}

table tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
}

table tbody tr:last-of-type {
    border-bottom: 2px solid #F8991B;
}

table tbody tr.active-row, table tbody tr.active-row:hover {
    background-color: #F8991B2f;
}

table tbody tr:hover {
    background-color: #F8991B0f;
}

@media only screen and (min-width: 768px) {
    .big-modal .modal-wrapper {
        width: 80%;
    }
}

@media only screen and (min-height: 768px) {
    .big-modal .modal-wrapper {
        height: 80%;
    }
}

.wider-alert {
    --min-width: 500px;
}

.top-modal {
    background-color: #000000a1;
}


// .styled-table {
//     border-collapse: collapse;
//     margin: 25px 0;
//     font-size: 0.9em;
//     font-family: sans-serif;
//     min-width: 400px;
//     box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
//     width: 100%;
// }

// .styled-table thead tr {
//     background-color: #fff;
//     color: #000;
//     text-align: left;
//     border-bottom: 1px solid #e7e7e7;
// }

// .styled-table th,
// .styled-table td {
//     padding: 12px 15px;
// }

// .styled-table tbody tr {
//     border-bottom: 1px solid #dddddd;
// }

// .styled-table tbody tr:nth-of-type(even) {
//     background-color: #f3f3f3;
// }

// .styled-table tbody tr:last-of-type {
//     border-bottom: 2px solid #F8991B;
// }

// .styled-table tbody tr.active-row, .styled-table tbody tr.active-row:hover {
//     background-color: #F8991B2f;
// }

// .styled-table tbody tr:hover {
//     background-color: #F8991B0f;
// }

// @media only screen and (min-width: 768px) {
//     .big-modal .modal-wrapper {
//         width: 80%;
//     }
// }

// @media only screen and (min-height: 768px) {
//     .big-modal .modal-wrapper {
//         height: 80%;
//     }
// }

// .wider-alert {
//     --min-width: 500px;
// }